import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from '../components/PageTitle'
import SEO from '../components/seo'
import useStyles from '../styles/generalStyles'
import GAOptOut from '../components/GAOptOut/GAOptOut'

const Datasecurity = () => {
  const classes = useStyles()
  const query = useStaticQuery(graphql`
{
    allMarkdownRemark(filter: {frontmatter: {id: {eq: "datasecurity"}}}) {
      nodes {
        frontmatter {
          title
        }
        html
      }
    }
  }
`)

  const pageTitle = query.allMarkdownRemark.nodes[0].frontmatter.title
  return (
    <Layout>
      <SEO title={pageTitle} />
      <PageTitle title={pageTitle} />
      <div className={classes.main} dangerouslySetInnerHTML={{ __html: query.allMarkdownRemark.nodes[0].html }} />
      <GAOptOut />
    </Layout>
  )
}
export default Datasecurity
