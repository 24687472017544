import React from "react"
import { Cookies } from "react-cookie-consent"
import Button from '@material-ui/core/Button'

const GAOptOut = ({ children }) => {
  const cookies = Cookies.get()

  const clearCookies = () => {
    Object.keys(cookies)
      .filter(i => !['gatsby-gdpr-google-analytics-legacy', 'gatsby-gdpr-google-analytics'].includes(i))
      .forEach(i => {
        Cookies.remove(i)
      })
    Object.keys(Cookies.get()).forEach(i => Cookies.set(i, false))
    window.location.reload()
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Button onClick={clearCookies} style={{ backgroundColor: '#ba000d', color: '#ffffff' }} variant='outlined'>
        Google Analytics Deaktivieren
    </Button>
    </div>
  )
}
export default GAOptOut
